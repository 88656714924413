// ================================================================================
// #TEXT ROTATOR
// ================================================================================
import 'script-loader!../vendor/replaceme';

const ROTATOR_SEL = '.textrotator';

function init() {
  const containers = Array.prototype.slice.call(document.querySelectorAll(ROTATOR_SEL));

  if (!containers || !containers.length) return;

  containers.forEach(
    container =>
      new ReplaceMe(container, {
        animation: 'fadeIn',
        speed: 2500,
        separator: ',',
        loopCount: 'infinite',
        autoRun: true
      })
  );
}

export default init;
