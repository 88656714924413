// ================================================================================
// #NAV COMPONENT
// ================================================================================

function nav() {
  return {
    isOpen: false,

    openNav() {
      this.isOpen = true;
    },

    closeNav() {
      this.isOpen = false;
    },

    toggleNav() {
      this.isOpen = !this.isOpen;
    },
  };
}

export default nav;
