import { getPageLang } from '../helpers/utils';

const lang = getPageLang(document);

const i18n = {
  label: {
    ru: 'Прикрепить документ',
    en: 'Attach files',
    fr: ' Attacher des fichiers',
  },
};

function file() {
  return {
    isFileChoosen: false,
    labelText: i18n.label[lang],

    handleFilesReset() {
      this.$refs.input.value = '';
      this.isFileChoosen = false;
      this.labelText = i18n.label[lang];
    },

    handleFilesChoose(target) {
      const { files } = target;

      this.labelText = files[0].name;
      this.isFileChoosen = true;
    },
  };
}

export default file;
