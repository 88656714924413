import validate from 'validate.js';
import axios from 'axios';

import { getPageLang } from '../helpers/utils';
import { isMobilePhone, isEmail } from '../helpers/validationUtils';
import {
  addCityAndReferrer,
  getSelectedInterests,
  uploadFileToDrive,
} from '../helpers/formUtils';
import { formMessages } from '../helpers/enums';
import { getCaptchaToken, verifyCaptcha } from '../helpers/captchaUtils';
import {
  trackGoogleAnanlytics,
  trackYandexMetrika,
} from '../helpers/metricsUtils';
import { getCookie } from '../helpers/cookiesUtils';

const EMAIL_FIELD_NAME = 'Email';
const PHONE_FIELD_NAME = 'PhoneNumber_countrycode';
const INTERESTS_FIELD_NAME = 'MultipleChoice';
const DOCUMENT_FIELD_NAME = 'FileUpload';
const USERNAME_FIELD_NAME = 'SingleLine';
const COMMENT_FIELD_NAME = 'MultiLine';
const REFERER_FIELD_NAME = 'SingleLine2';
const CITY_FIELD_NAME = 'SingleLine3';

const captchaSiteKey = '6LfdcdMUAAAAAGjDEgPZf-lGYgWQLMeiXm3xDGJE';
const lang = getPageLang(document);

const rules = {
  name: {
    presence: {
      allowEmpty: false,
      message: formMessages.presence[lang],
    },
  },
  phoneOrEmail: {
    presence: {
      allowEmpty: false,
      message: formMessages.presence[lang],
    },
    phoneOrEmail: true,
  },
  comment: {
    presence: {
      allowEmpty: false,
      message: formMessages.presence[lang],
    },
  },
};

const openDialogEvent = new CustomEvent('dialog:open', {
  detail: { id: 'success' },
});
const restSelectEvent = new CustomEvent('select:reset');

validate.validators.phoneOrEmail = function (value) {
  if (!value) return null;

  if (!isMobilePhone(value) && !isEmail(value))
    return formMessages.phoneOrEmail[lang];
};

async function sendEmail(form) {
  const formData = new FormData();
  const username = form.elements[USERNAME_FIELD_NAME];
  const phone = form.elements[PHONE_FIELD_NAME];
  const email = form.elements[EMAIL_FIELD_NAME];
  const comment = form.elements[COMMENT_FIELD_NAME];
  const referer = getCookie('user_referrer');
  const firstVisit = getCookie('first_visit');
  const city = form.elements[CITY_FIELD_NAME];
  const selectedInterests = getSelectedInterests(
    form.elements[INTERESTS_FIELD_NAME].options
  );
  const files = form.elements[DOCUMENT_FIELD_NAME].files;
  const { data } = await uploadFileToDrive(files);

  formData.set('name', username.value);
  formData.set('phone', phone && phone.value ? phone.value : '');
  formData.set('email', email && email.value ? email.value : '');
  formData.set('comment', comment.value);
  formData.set('interests', selectedInterests);
  formData.set('doc_url', data ? data.url : '');
  formData.set('city', city.value);
  formData.set('referer', referer);
  formData.set('first_visit', firstVisit || '');
  formData.set('type', 'request');

  return axios({
    method: 'POST',
    url: '/send.php',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
}

function contactsForm() {
  return {
    fields: {
      name: null,
      phoneOrEmail: null,
      comment: null,
    },
    errors: {
      name: '',
      phoneOrEmail: '',
      comment: '',
    },
    goalName: null,
    isSubmiting: false,

    contactsFormInit(form) {
      const metricsGoalInput = form.elements['metrics-goal-name'];

      if (metricsGoalInput) this.goalName = metricsGoalInput.value;

      addCityAndReferrer(form);
    },

    validateField(target) {
      const { id, value } = target;
      const errors = validate.single(this.fields[id], rules[id]) || [];

      this.errors[id] = errors[0] || '';

      if (isMobilePhone(value)) {
        target.setAttribute('name', PHONE_FIELD_NAME);
        target.setAttribute('fieldType', 11);
      }

      if (isEmail(value)) {
        target.setAttribute('name', EMAIL_FIELD_NAME);
        target.setAttribute('fieldType', 9);
      }
    },

    addPrefixToInput(input) {
      const prefix = input.getAttribute('prefixValue');
      input.value = `${prefix} - ${this.fields.comment}`;
    },

    resetForm() {
      Object.keys(this.fields).forEach((field) => (this.fields[field] = null));
    },

    validateForm(target) {
      const errors = validate(this.fields, rules) || {};
      const captchaField = target.elements['g-recaptcha-response'];
      const commentInput = target.querySelector('#comment');

      // If no errors submit form
      if (validate.isEmpty(errors)) {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(captchaSiteKey, { action: 'homepage' })
            .then((token) => {
              captchaField.value = token;

              verifyCaptcha(token).then((res) => {
                const { success, score } = res.data;

                if (!success || score < 0.9) return;

                this.isSubmiting = true;
                this.addPrefixToInput(commentInput);
                sendEmail(target).then(() => {
                  this.isSubmiting = false;
                  this.resetForm();
                  window.dispatchEvent(restSelectEvent);
                  window.dispatchEvent(openDialogEvent);

                  trackGoogleAnanlytics(this.goalName);
                  trackYandexMetrika(this.goalName);
                });
              });
            });
        });
        return;
      }

      // Show errors
      Object.keys(this.fields).forEach(
        (field) => (this.errors[field] = errors[field] || '')
      );
    },
  };
}

export default contactsForm;
