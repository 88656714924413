import axios from 'axios';

function getCity() {
  axios
    .get('https://api.ipgeolocation.io/ipgeo?apiKey=e95e06fc1ce64e9db54ec18a58a2db56&lang=ru')
    .then((res) => {
      const { city } = res.data;
      localStorage.setItem('_app_geo_city', city);
    })
    .catch((err) => console.log(err));
}

export default getCity;
