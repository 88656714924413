// ================================================================================
// #STAGES COMPONENT
// ================================================================================

function stages() {
  return {
    selected: null,

    switchPanel(id) {
      this.selected = id;
    },

    isPanelSelected(id) {
      return this.selected === id;
    },
  };
}

export default stages;
