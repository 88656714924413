// ================================================================================
// #SEARCH COMPONENT
// ================================================================================

import axios from 'axios';
import debounce from 'lodash/debounce';
import { getPageLang } from '../helpers/utils';

const SEARCH_SEL = '.search';
const SEARCH_INPUT_SEL = '.search-input';
const SEARCH_RESET_SEL = '.search-reset';
const SEARCH_RESULTS_SEL = '.search-results';
const SEARCHING = 'is-searching';
const IS_EMPTY = 'is-empty';

const lang = getPageLang(document);
const l18n = {
  title: {
    ru: 'Статьи',
    en: 'Articles',
    fr: 'Article',
  },
  empty: {
    title: {
      ru: 'Ой, тут пусто',
      en: 'Oops, no information found',
      fr: 'Oups, c’est vide',
    },
    message: {
      ru:
        'Пожалуйста, попробуйте <br> перефразировать или найти <br> что-то другое',
      en: 'Please try to use other key words',
      fr: 'Veuillez essayer de reformuler ou de trouver autre chose',
    },
  },
};

function Search(element) {
  const form = element;
  const input = form.querySelector(SEARCH_INPUT_SEL);
  const reset = form.querySelector(SEARCH_RESET_SEL);
  const results = form.querySelector(SEARCH_RESULTS_SEL);

  function renderResults(data) {
    const itemTeplate = ({ link, title }) => `
      <li>
        <a href=${link} class="link hover:text-electricviolet">${title}</a>
      </li>
    `;
    const items = data.reduce(
      (acc, item) =>
        (acc += itemTeplate({ title: item.title.rendered, link: item.link })),
      []
    );
    const list = `
      <ul class="search-list">
        <li class="search-title">${l18n.title[lang]}</li>
        ${items}
      </ul>
    `;

    form.classList.add(SEARCHING);
    reset.removeAttribute('disabled');

    results.innerHTML = list;
  }

  function renderEmpty() {
    const output = `
      <img src="/img/search-artwork.svg" alt="" class="mx-auto mb-6" />
      <div class="text-center text-gullgray">
        <h4 class="text-xs mb-2">${l18n.empty.title[lang]}</h4>
        <p class="text-9">${l18n.empty.message[lang]}</p>
      </div>
    `;

    form.classList.add(IS_EMPTY);
    reset.removeAttribute('disabled');
    results.innerHTML = output;
  }

  function fetchResults(value) {
    const url = form.action + value;
    const method = form.method;

    axios({ url, method })
      .then((res) =>
        res.data.length ? renderResults(res.data) : renderEmpty()
      )
      .catch(() => alert('Error. Try again later'));
  }

  function handleInput(event) {
    const { value } = event.target;

    if (value.length) {
      // form.classList.add(SEARCHING);
      // reset.removeAttribute('disabled');
      fetchResults(value);
    } else {
      form.classList.remove(SEARCHING);
      reset.setAttribute('disabled', true);
    }
  }

  function handleResetClick() {
    form.classList.remove(SEARCHING);
    form.classList.remove(IS_EMPTY);
    form.reset();
    results.innerHTML = '';
    reset.setAttribute('disabled', true);
  }

  form.addEventListener('submit', (event) => event.preventDefault());
  input.addEventListener('input', debounce(handleInput, 500), false);
  reset.addEventListener('click', handleResetClick, false);
}

function init() {
  const instances = Array.prototype.slice.call(
    document.querySelectorAll(SEARCH_SEL)
  );

  if (!instances || !instances.length) return;

  instances.forEach((instance) => Search(instance));
}

export default init;
