import { compose, head, path, split } from 'ramda';

export const getPageLang = compose(
  head,
  split('-'),
  path(['documentElement', 'lang'])
);

export const scrollTo = ({ distance, instant }) => {
  if (!distance || distance === 0) return;

  window.scroll({
    behavior: instant ? 'instant' : 'smooth',
    top: distance,
    left: 0,
  });
};

export function calcDistance({ el, navbar }) {
  return (
    document.documentElement.scrollTop +
    el.getBoundingClientRect().top -
    navbar.scrollHeight
  );
}
