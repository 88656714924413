import validate from 'validate.js';
import axios from 'axios';

import { getPageLang } from '../helpers/utils';
import { isMobilePhone } from '../helpers/validationUtils';
import { addCityAndReferrer } from '../helpers/formUtils';
import { formMessages } from '../helpers/enums';
import { getCaptchaToken, verifyCaptcha } from '../helpers/captchaUtils';
import {
  trackGoogleAnanlytics,
  trackYandexMetrika,
} from '../helpers/metricsUtils';
import { getCookie } from '../helpers/cookiesUtils';

const PHONE_FIELD_NAME = 'PhoneNumber_countrycode';
const REFERER_FIELD_NAME = 'SingleLine2';
const CITY_FIELD_NAME = 'SingleLine3';

const captchaSiteKey = '6LfdcdMUAAAAAGjDEgPZf-lGYgWQLMeiXm3xDGJE';
const lang = getPageLang(document);

const rules = {
  callbackPhone: {
    presence: {
      allowEmpty: false,
      message: formMessages.presence[lang],
    },
    phone: true,
  },
};

validate.validators.phone = function (value) {
  if (!value) return null;

  if (!isMobilePhone(value)) return formMessages.phone[lang];
};

const openDialogEvent = new CustomEvent('dialog:open', {
  detail: { id: 'success' },
});

function sendEmail(form) {
  const formData = new FormData();
  const phone = form.elements[PHONE_FIELD_NAME];
  const referer = getCookie('user_referrer');
  const firstVisit = getCookie('first_visit');
  const city = form.elements[CITY_FIELD_NAME];

  formData.set('phone', phone.value);
  formData.set('city', city.value);
  formData.set('referer', referer);
  formData.set('first_visit', firstVisit || '');
  formData.set('type', 'callback');

  return axios({
    method: 'POST',
    url: '/send.php',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
}

function callbackForm() {
  return {
    fields: {
      callbackPhone: null,
    },
    errors: {
      callbackPhone: '',
    },
    goalName: null,
    isSubmiting: false,

    callbackFormInit(form) {
      const metricsGoalInput = form.elements['metrics-goal-name'];

      if (metricsGoalInput) this.goalName = metricsGoalInput.value;

      addCityAndReferrer(form);
    },

    validateField({ id }) {
      const errors = validate.single(this.fields[id], rules[id]) || [];

      this.errors[id] = errors[0] || '';
    },

    resetForm() {
      Object.keys(this.fields).forEach((field) => (this.fields[field] = null));
    },

    validateForm(target) {
      const errors = validate(this.fields, rules) || {};
      const captchaField = target.elements['g-recaptcha-response'];

      // If no errors submit form
      if (validate.isEmpty(errors)) {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(captchaSiteKey, { action: 'homepage' })
            .then((token) => {
              captchaField.value = token;

              verifyCaptcha(captchaField.value).then((res) => {
                const { success, score } = res.data;

                if (!success || score < 0.9) return;

                this.isSubmiting = true;

                sendEmail(target).then(() => {
                  this.isSubmiting = false;
                  this.resetForm();
                  window.dispatchEvent(openDialogEvent);

                  trackGoogleAnanlytics(this.goalName);
                  trackYandexMetrika(this.goalName);
                });
              });
            });
        });
        return;
      }

      // Show errors
      Object.keys(this.fields).forEach(
        (field) => (this.errors[field] = errors[field] || '')
      );
    },
  };
}

export default callbackForm;
