import { scrollTo } from '../helpers/utils';

const navbar = document.querySelector('.navbar');

function vacancies() {
  return {
    selected: null,

    scrollToDetails(id) {
      if (this.selected !== id) return;

      const details = document.getElementById(id);

      setTimeout(() => {
        scrollTo({ distance: details.offsetTop - navbar.scrollHeight });
      }, 300);
    },

    isVacancyMatch(id) {
      return this.selected === id;
    },

    toggleDetailsVisible(id) {
      this.selected = this.selected === id ? null : id;
    },

    closeDetails() {
      this.selected = null;
    },
  };
}

export default vacancies;
