import { getCookie, setCookie } from '../helpers/cookiesUtils';

function cookieMessage() {
  const messageDismissCookie = getCookie('cookieMessageDismiss');

  function setMessageCookie() {
    setCookie('cookieMessageDismiss', 1, { 'max-age': 604800 });
  }

  return {
    isOpen: false,

    showMessage() {
      this.isOpen = true;
    },

    hideMessage() {
      this.isOpen = false;
      setMessageCookie();
    },

    init() {
      if (messageDismissCookie) return;

      setTimeout(() => {
        this.showMessage();
      }, 300);
    },
  };
}

export default cookieMessage;
