// ================================================================================
// #REVIEWS SECTION
// ================================================================================
import { Swiper, Autoplay } from 'swiper/js/swiper.esm.js';

Swiper.use([Autoplay]);

const REVIEWS_SEL = '.reviews';

const options = {
  loop: true,
  slidesPerView: 'auto',
  watchSlidesProgress: true,
  watchSlidesVisibility: true,
  centeredSlides: true,
  spaceBetween: 16,
  roundLengths: true,
  slideToClickedSlide: true,
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
};

function init() {
  const container = document.querySelector(REVIEWS_SEL);

  if (!container) return;

  const swiper = new Swiper(container, options);
}

export default init;
