import { Swiper } from 'swiper/js/swiper.esm.js';

const CAROUSEL_SEL = '.carousel';

const options = {
  loop: true,
  slidesPerView: 'auto',
  watchSlidesProgress: true,
  watchSlidesVisibility: true,
  centeredSlides: true,
  spaceBetween: 16,
  roundLengths: true,
  slideToClickedSlide: true,
};

function init() {
  const carousel = document.querySelector(CAROUSEL_SEL);

  if (!carousel) return;

  new Swiper(carousel, options);
}

export default init;
