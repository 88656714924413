import queryString from 'query-string';
import {
  __,
  anyPass,
  always,
  compose,
  cond,
  converge,
  curry,
  equals,
  filter,
  head,
  ifElse,
  includes,
  identity,
  isEmpty,
  isNil,
  keys,
  nth,
  path,
  split,
  T,
  prop,
  useWith,
  tap
} from 'ramda';

const sources = {
  socials: [
    'instagram.com',
    'instagram',
    'youtube.com',
    'youtube',
    'twitter',
    'twitter.com',
    'telegram',
    'vk',
    'vk.com',
    'away.vk.com',
    'ok.ru',
  ],
  facebookAds: ['fbtarget'],
  clientappAds: ['clientapp'],
  googleOrganic: ['www.google.com', 'google.com'],
  googleAds: ['google'],
  yandexOrganic: ['yandex.ru', 'yandex.ua', 'yandex.by'],
  yandexAds: ['yandex'],
  ratingruneta: ['ratingruneta.ru', 'ratingruneta'],
  ruward: ['ruward.ru', 'ruward'],
  tagline: ['tagline.ru', 'tagline'],
  workspace: ['workspace.ru', 'workspace'],
  sameorigin: ['sameorigin'],
};

const getSourceCategory = useWith(filter, [includes, identity]);
const getCategoryName = curry(compose(head, keys, getSourceCategory));
const getHost = path(['location', 'host']);
const getHostFromRef = compose(nth(2), split('/'), prop('referrer'));
const isSameSite = converge(equals, [getHost, getHostFromRef]);
const isEmptyRef = compose(isEmpty, prop('referrer'));
const getRef = ifElse(
  anyPass([isEmptyRef, isSameSite]),
  always('sameorigin'),
  getHostFromRef
);
const getUtmSource = compose(
  prop('utm_source'),
  queryString.parse,
  path(['location', 'search'])
);

const isUtmNil = compose(isNil, getUtmSource);
const getUtmOrRef = ifElse(isUtmNil, getRef, getUtmSource);

// prettier-ignore
const detectTraficSource = cond([
  [equals('socials'),       always('Социальные сети')],
  [equals('googleOrganic'), always('Органика Google')],
  [equals('facebookAds'),   always('Реклама Facebook')],
  [equals('clientappAds'),   always('Реклама Clientapp')],
  [equals('googleAds'),     always('Реклама Google')],
  [equals('yandexOrganic'), always('Органика Яндекс')],
  [equals('yandexAds'),     always('Реклама Яндекс')],
  [equals('ratingruneta'),  always('Переход с ratingruneta.ru')],
  [equals('ruward'),        always('Переход с ruward.ru')],
  [equals('tagline'),       always('Переход с tagline.ru')],
  [equals('workspace'),     always('Переход с workspace.ru')],
  [equals('sameorigin'),    always('Прямой заход')],
  [T,                       always('Неизвестно')],
]);
const log = (msg) => tap(x => localStorage.setItem(msg, x));
export const getTrafficCategory = compose(
  detectTraficSource,
  log('value after add1: '),
  getCategoryName(__, sources),
  log('value after add2: '),
  getUtmOrRef
);
