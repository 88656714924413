import { getPageLang } from '../helpers/utils';

let marker;
let map;

const lang = getPageLang(document);

const locations = {
  moscow: { lat: 55.781983, lang: 37.670134 },
  ryazan: { lat: 54.63419, lang: 39.756114 },
  paris: { lat: 48.837233, lang: 2.279633 },
  sacramento: { lat: 38.574265, lang: -121.308496 },
};

const langs = {
  fr: 'paris',
  ru: 'moscow',
  en: 'sacramento',
};

const mapStyle = [
  {
    elementType: 'geometry',
    // stylers: [{ color: '#f5f5f5' }]
  },
  {
    // elementType: 'labels.icon',
    // stylers: [{ visibility: 'off' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    // featureType: 'administrative',
    // elementType: 'geometry',
    // stylers: [{ visibility: 'off' }]
  },
  {
    // featureType: 'administrative.land_parcel',
    // elementType: 'labels',
    // stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    // featureType: 'road',
    // elementType: 'labels.icon',
    // stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#b3b3b3' }],
  },
  {
    // featureType: 'road.arterial',
    // elementType: 'labels.text.stroke',
    // stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dadada' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    // stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c9c9c9' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
];

const mapNavs = Array.prototype.slice.call(
  document.querySelectorAll('.map-link')
);

function changeNavActive(id) {
  const prevLink = mapNavs.filter((link) =>
    link.classList.contains('is-active')
  )[0];
  const nextLink = mapNavs.filter((link) => link.id === id)[0];

  if (prevLink) {
    prevLink.classList.remove('is-active');
  }

  nextLink.classList.add('is-active');
}

mapNavs.forEach((link) =>
  link.addEventListener(
    'click',
    (event) => {
      const location = locations[event.target.id] || {};
      changeMarkerPos(location.lat, location.lang);
      changeNavActive(event.target.id);
    },
    false
  )
);

function init() {
  const langLocation = langs[lang] || 'en';
  const activeLocation = locations[langLocation];
  const styledMap = new google.maps.StyledMapType(mapStyle, {
    name: 'Styled Map',
  });

  const mapProp = {
    center: new google.maps.LatLng(activeLocation.lat, activeLocation.lang),
    zoom: 18,
    panControl: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    overviewMapControl: false,
    rotateControl: true,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  };

  map = map = new google.maps.Map(
    document.getElementById('map-canvas'),
    mapProp
  );

  map.mapTypes.set('map_style', styledMap);
  map.setMapTypeId('map_style');

  marker = new google.maps.Marker({
    position: new google.maps.LatLng(activeLocation.lat, activeLocation.lang),
    animation: google.maps.Animation.DROP,
    icon: '/img/map-marker.svg',
  });

  marker.setMap(map);
  map.panTo(marker.position);

  changeNavActive(langLocation);
}

function changeMarkerPos(lat, lon) {
  const myLatLng = new google.maps.LatLng(lat, lon);
  marker.setPosition(myLatLng);
  map.panTo(myLatLng);
}

function apiReadyChecker() {
  !window.google
    ? setTimeout(apiReadyChecker, 100)
    : google.maps.event.addDomListener(window, 'load', init);
}

export default apiReadyChecker;
