function selectMultiple() {
  return {
    selected: [],
    listOpen: false,

    isListHidden() {
      return this.selected.length + 1 > this.$refs.list.childElementCount;
    },

    updateSelect(action, value) {
      const { select } = this.$refs;
      const targetOption = select.querySelector(`option[value="${value}"]`);

      if (!action || !targetOption) return;

      if (action === 'add') {
        targetOption.setAttribute('selected', 'selected');
      }

      if (action === 'remove') {
        targetOption.removeAttribute('selected');
      }
    },

    renderList(action, id) {
      const { list } = this.$refs;
      const targetEl = list.querySelector(`#${id}`);

      if (!action || !targetEl) return;

      if (action === 'hide') {
        targetEl.classList.add('hidden');
      }

      if (action === 'show') {
        targetEl.classList.remove('hidden');
      }
    },

    handleOptionRemove(id, value) {
      this.selected = this.selected.filter((item) => item.id !== id);
      this.renderList('show', id);
      this.updateSelect('remove', value);
    },

    handleOptionAdd(target) {
      const { id, textContent } = target;

      this.selected.push({ id, value: textContent });
      this.renderList('hide', id);
      this.updateSelect('add', textContent);
    },

    resetSelect() {
      this.selected.forEach((item) => {
        this.renderList('show', item.id);
        this.updateSelect('remove', item.value);
      });

      this.selected = [];
    },
  };
}

export default selectMultiple;
