export const isMobilePhone = (str) => {
  const trimmed = str.replace(/\s/gi, '');

  return trimmed.startsWith('+') && trimmed.length === 12;
};

export const isEmail = (str) => {
  const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regexp.test(String(str).toLowerCase());
};
