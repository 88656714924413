import { Swiper, Navigation } from 'swiper/js/swiper.esm.js';

Swiper.use([Navigation]);

const CONTAINER_SEL = '.faq';
const PREV_SEL = '.faq-prev';
const NEXT_SEL = '.faq-next';

const options = {
  slidesPerView: 1,
  spaceBetween: 8,
  navigation: {
    nextEl: NEXT_SEL,
    prevEl: PREV_SEL,
    disabledClass: 'is-disabled',
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
};

function init() {
  const container = document.querySelector(CONTAINER_SEL);

  if (!container) return;

  new Swiper(container, options);
}

export default init;
