function init() {
  const reasonDialogEvent = new CustomEvent('dialog:open', {
    detail: { id: 'reason' },
  });

  const url = new URL(window.location);
  const reason = url.searchParams.has('reason');

  if (!reason) return;

  window.dispatchEvent(reasonDialogEvent);

  setTimeout(() => {
    window.history.replaceState({}, document.title, '/' + 'blog');
  }, 120000);
}

export default init;
