// ================================================================================
// #JUMPER COMPONENT
// ================================================================================

import { scrollTo, calcDistance } from '../helpers/utils';

const JUMPER_SEL = '.js-jumper';
const NAME_FIELD = 'SingleLine';

function Jumper(element) {
  const navbar = document.querySelector('.navbar');

  function handleJumperClick(event) {
    event.preventDefault();
    const targetEl = document.querySelector(this.hash);
    const target = targetEl ? calcDistance({ el: targetEl, navbar }) : 0;

    scrollTo({ distance: target });
  }

  function handleHashChange() {
    const hash = window.location.hash;

    if (!hash) return;

    const targetEl = document.querySelector(hash);
    const target = calcDistance({ el: targetEl, navbar });

    scrollTo({ distance: target, instant: true });
  }

  element.addEventListener('click', handleJumperClick, false);
  window.addEventListener('load', handleHashChange, false);
}

function init() {
  const jumpers = Array.prototype.slice.call(document.querySelectorAll(JUMPER_SEL));

  if (!jumpers || !jumpers.length) return;

  jumpers.forEach((jumper) => Jumper(jumper));
}

export default init;
