export function trackGoogleAnanlytics(goal) {
  if (window.ga) {
    ga('send', 'event', 'ContactUs', goal);
  }
}

export function trackYandexMetrika(goal) {
  if (window.ym) {
    ym('28874515', 'reachGoal', goal);
  }
}
