// ================================================================================
// #WHITEPAPER COMPONENT
// ================================================================================

import { whitepapperLabels } from '../helpers/enums';
import { getPageLang } from '../helpers/utils';

const lang = getPageLang(document);

function whitepaper() {
  return {
    isExpanded: false,
    labelText: whitepapperLabels.expandLabel[lang],

    toggleWhitepapper() {
      this.isExpanded = !this.isExpanded;
      this.labelText = !this.isExpanded
        ? whitepapperLabels.expandLabel[lang]
        : whitepapperLabels.collapseLabel[lang];
    },
  };
}

export default whitepaper;
