import axios from 'axios';
import { compose, join, filter, map, prop, toString } from 'ramda';

import { getCookie } from './cookiesUtils';

export function addCityAndReferrer(form) {
  const referrerInput = form.querySelector('.referer');
  const userGeoInput = form.querySelector('.usercity');
  const referrerCookie = getCookie('user_referrer');
  const userGeolocation = localStorage.getItem('_app_geo_city');

  if (referrerInput) {
    referrerInput.value = referrerCookie || '';
  }

  if (userGeoInput) {
    userGeoInput.value = userGeolocation || '';
  }
}

export function uploadFileToDrive(filelist) {
  if (!filelist.length) return { url: null };

  const formData = new FormData();

  formData.append('file', filelist[0]);

  return axios.post(
    'https://bots.appcraft.team/api/google-sheet/upload-google-drive',
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
}

const isOptionSelected = (option) => option.selected;

export const getSelectedInterests = compose(
  toString,
  join(', '),
  map(prop('value')),
  filter(isOptionSelected)
);
