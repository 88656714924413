import mixitup from 'mixitup';

const DESIGN_LIST_SEL = '.design';
const DESIGN_UNIT_SEL = '.design-unit';

const mixerOptions = {
  selectors: {
    target: DESIGN_UNIT_SEL,
  },
  animation: {
    duration: 250,
    nudge: true,
    reverseOut: false,
    animateResizeContainer: false,
    animateResizeTargets: false,
    effects: 'fade scale(0.01) translateZ(-100px)',
  },
  controls: {
    scope: 'local',
  },
};

export default function designSection() {
  const mixer = mixitup(DESIGN_LIST_SEL, mixerOptions);

  return {
    handleUnitMouseenter(event) {
      const image = event.target.querySelector('.design-image');
      const video = event.target.querySelector('.design-video');

      if (!video) return;

      image.style.opacity = 0;

      video.play();
    },

    handleUnitMouseleave(event) {
      const image = event.target.querySelector('.design-image');
      const video = event.target.querySelector('.design-video');

      if (!video) return;

      image.style.opacity = 1;

      video.pause();
    },

    unit: {
      ['@mouseenter']: 'handleUnitMouseenter',
      ['@mouseleave']: 'handleUnitMouseleave',
    },
  };
}
