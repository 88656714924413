import axios from 'axios';
import { compose, fromPairs, map, prop, split } from 'ramda';

import { setCookie } from '../helpers/cookiesUtils';

const ipDetectServiceUrl = 'https://www.cloudflare.com/cdn-cgi/trace';

const getIpFromStr = compose(
  prop('ip'),
  fromPairs,
  map(split('=')),
  split('\n'),
  prop('data')
);

const setIpToCookies = (res) => {
  const ip = getIpFromStr(res);

  setCookie('userip', ip);
};

export default () => {
  axios
    .get(ipDetectServiceUrl)
    .then(setIpToCookies)
    .catch((err) => console.log(err));
};
