// ================================================================================
// #PORTFOLIO COMPONENT
// ================================================================================

import mixitup from 'mixitup';

const PORTFOLIO_SEL = '.portfolio-wrap';
const PORTFOLIO_WORK_SEL = '.portfolio-work';

const mixerOptions = {
  selectors: {
    target: PORTFOLIO_WORK_SEL,
  },
  animation: {
    duration: 250,
    nudge: true,
    reverseOut: false,
    animateResizeContainer: false,
    animateResizeTargets: false,
    effects: 'fade scale(0.01) translateZ(-100px)',
  },
  controls: {
    enable: true,
    scope: 'local',
  },
};

function portfolio() {
  const mixer = mixitup(PORTFOLIO_SEL, mixerOptions);

  return {
    selected: null,

    scrollToDetails(id) {
      if (this.selected !== id) return;

      const details = document.getElementById(id);

      if (!details) return;

      setTimeout(() => {
        details.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }, 300);
    },

    isWorkMatch(id) {
      return this.selected === id;
    },

    toggleDetailsVisible(id) {
      this.selected = this.selected === id ? null : id;
    },

    closeDetails() {
      this.selected = null;
    },
  };
}

export default portfolio;
