import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { constructN, compose, invoker } from 'ramda';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const dayjsConstructor = constructN(0, dayjs);
const dayjsCustomFormat = constructN(2, dayjs);
const formatDate = invoker(1, 'format');
const setTimezone = invoker(1, 'tz');
const addToDate = invoker(2, 'add');
const printDate = invoker(0, 'toDate');
const formatToISO = invoker(1, 'format');

export const getFormatedCurrentDate = compose(
  formatDate('DD-MM-YYYY HH:mm'),
  dayjsConstructor
);

export const getDateInMoscowTimezone = compose(
  formatDate('DD-MM-YYYY HH:mm'),
  setTimezone('Europe/Moscow'),
  dayjsConstructor
);

export const addFiveMinToCurrentDate = compose(
  printDate,
  addToDate(5, 'minute'),
  dayjsConstructor
);

export const convertDateToISO = compose(
  formatToISO('YYYY-MM-DDTHH:mm:ss'),
  dayjsCustomFormat
);
