// ================================================================================
// #STRIPE COMPONENT
// ================================================================================

import throttle from 'lodash/throttle';

const STRIPE_SEL = '.stripe';
const SCROLL_SPEED = 0.3;
const NOISE_SPEED = 0.004;
const NOISE_AMOUNT = 5;
const CANVAS_WIDTH = 2800;

// prettier-ignore
const bubblesSpecs = [
  { s: 1,     x: 1071, y: 233 },
  { s: 1,     x: 413,  y: 367 },
  { s: 0.85,  x: 1761, y: 372 },
  { s: 0.65,  x: 2499, y: 79  },
  { s: 0.85,  x: 2704, y: 334 },
  { s: 0.85,  x: 2271, y: 356 },
  { s: 0.8,   x: 2545, y: 387 },
  { s: 0.65,  x: 276,  y: 256 },
  { s: 0.85,  x: 1230, y: 365 },
  { s: 0.85,  x: 444,  y: 193 },
  { s: 1,     x: 624,  y: 111 },
  { s: 1,     x: 1308, y: 208 },
  { s: 0.65,  x: 907,  y: 88  },
  { s: 0.85,  x: 613,  y: 320 },
  { s: 0.85,  x: 323,  y: 60  },
  { s: 0.85,  x: 119,  y: 327 },
  { s: 0.85,  x: 1440, y: 342 },
  { s: 0.85,  x: 1929, y: 293 },
  { s: 0.85,  x: 2135, y: 198 },
  { s: 0.85,  x: 2276, y: 82  },
  { s: 0.85,  x: 2654, y: 182 },
  { s: 0.85,  x: 2783, y: 60  },
  { s: 1,     x: 1519, y: 118 },
  { s: 0.85,  x: 1134, y: 85  },
  { s: 0.85,  x: 1773, y: 148 },
  { s: 1,     x: 2098, y: 385 },
  { s: 1,     x: 2423, y: 244 },
  { s: 0.65,  x: 1080, y: 415 },
  { s: 0.85,  x: 795,  y: 226 },
  { s: 1,     x: 75,   y: 103 },
  { s: 1,     x: 1620, y: 271 },
  { s: 1,     x: 2895, y: 271 },
  { s: 1,     x: 1990, y: 75  },
  { s: 0.65,  x: 1344, y: 65  },
  { s: 1,     x: 901,  y: 385 },
  { s: 0.65,  x: 1581, y: 422 },
  { s: 0.65,  x: 1929, y: 433 },
  { s: 0.65,  x: 252,  y: 422 },
  { s: 0.65,  x: 718,  y: 436 },
  { s: 0.65,  x: 1669, y: 48  },
  { s: 0.65,  x: 2835, y: 411 },
  // { s: 0.65,  x: 2401, y: 426 },
  // { s: 0.65,  x: 2902, y: 129 },
  // { s: 0.65,  x: 40,   y: 448 },
  // { s: 0.85,  x: 3000, y: 380 },
  // { s: 1,     x: 3070, y: 214 },
  // { s: 0.85,  x: 3120, y: 73  },
  // { s: 0.65,  x: 3152, y: 342 },
];

function Stripe(container, specs) {
  const bubbles = [];
  const introDelay = 1500;
  const introDuration = 1500;
  let firstTick = null;
  let lastTick = 0;
  let playing = 0;

  function createBubble({ index, spec }) {
    const bubble = {};
    const { x, y, s } = spec;
    const el = document.createElement('div');

    el.className = `stripe-bubble stripe-bubble-${index + 1}`;
    bubble.el = el;
    bubble.index = index;
    bubble.posX = x;
    bubble.posY = y;
    bubble.scale = s;
    bubble.introProgress = 0;
    bubble.introDelay = Math.random() * introDelay;
    bubble.noiseSeedX = Math.floor(Math.random() * 64000);
    bubble.noiseSeedY = Math.floor(Math.random() * 64000);

    upadateBubble(bubble);

    bubbles.push(bubble);
    container.appendChild(el);
  }

  function upadateBubble(bubble) {
    bubble.noiseSeedX += NOISE_SPEED;
    bubble.noiseSeedY += NOISE_SPEED;

    const randomX = noise.simplex2(bubble.noiseSeedX, 0);
    const randomY = noise.simplex2(bubble.noiseSeedY, 0);

    bubble.posX -= SCROLL_SPEED;

    const xWithNoise = bubble.posX + randomX * NOISE_AMOUNT;
    const yWithNoise = bubble.posY + randomY * NOISE_AMOUNT;

    if (bubble.posX < -200) {
      bubble.posX = CANVAS_WIDTH;
    }

    bubble.el.style.transform = `translate(${xWithNoise}px, ${yWithNoise}px) scale(${bubble.scale})`;
  }

  function createBubblesList() {
    specs.forEach((spec, index) => createBubble({ index, spec }));
  }

  function updateStripe(scene) {
    firstTick || (firstTick = scene);

    const n = (scene -= firstTick) - lastTick;

    lastTick = scene;

    bubbles.forEach((bubble) => {
      if (bubble.introProgress < 1 && scene > introDelay) {
        bubble.introProgress = Math.min(
          1,
          bubble.introProgress + n / introDuration
        );
      }

      upadateBubble(bubble);
    });

    playing && requestAnimationFrame(updateStripe);
  }

  function isAnimationAllowed() {
    const scene = container.getBoundingClientRect();

    if ((scene.bottom < 0 || scene.top > window.innerHeight) && playing === 1) {
      playing = 0;
    } else if (
      scene.bottom > 0 &&
      scene.top < window.innerHeight &&
      playing === 0
    ) {
      playing = 1;
      requestAnimationFrame((scene) => updateStripe(scene));
    }
  }

  function init() {
    var time = new Date();

    createBubblesList();
    isAnimationAllowed();

    window.addEventListener('scroll', throttle(isAnimationAllowed, 500));
  }

  init();
}

function moduleInit() {
  const container = document.querySelector(STRIPE_SEL);

  if (!container) return;

  noise.seed(Math.floor(Math.random() * 64000));

  Stripe(container, bubblesSpecs);
}

export default moduleInit;
