import axios from 'axios';
import Cookies from 'js-cookie';
import { allPass, compose, complement, equals, isNil, path, prop } from 'ramda';

import dayjs from 'dayjs';

import {
  addFiveMinToCurrentDate,
  convertDateToISO,
} from '../helpers/dateUtils';

const ADDRESS_SEL = '.js-address';

const isAddressNode = compose(
  equals('address-link'),
  path(['selection', 'anchorNode', 'parentNode', 'id'])
);
const isRangeType = compose(equals('Range'), path(['selection', 'type']));
const isAddressNodeSelected = compose(allPass([isAddressNode, isRangeType]));
const isEmptyCookie = compose(isNil, prop('interactCookie'));
const isNotEmptyCookie = complement(isEmptyCookie);
const isAllreadyInteract = complement(
  allPass([isAddressNodeSelected, isEmptyCookie])
);

const setInteractCookie = () =>
  Cookies.set('user_address_interact', 1, {
    expires: addFiveMinToCurrentDate(),
  });

const logUserInfo = () => {
  const ip = Cookies.get('userip');
  const referer = Cookies.get('user_referrer');
  const firstVisit = Cookies.get('first_visit');
  const firstVisitISO = convertDateToISO(firstVisit, 'DD-MM-YYYY HH:mm');

  const data = {
    ipAddress: ip,
    channel: referer,
    firstAppeal: firstVisitISO,
  };

  axios
    .post('https://bots.appcraft.team/api/google-sheet/clients', data)
    .catch((err) => console.log(err));
};

const handleAddressSelect = () => {
  const interactCookie = Cookies.get('user_address_interact');
  const selection = window.getSelection();

  if (isAllreadyInteract({ selection, interactCookie })) return;

  logUserInfo();
  setInteractCookie();
};

const onAddressClick = () => {
  const interactCookie = Cookies.get('user_address_interact');

  if (isNotEmptyCookie({ interactCookie })) return;

  logUserInfo();
  setInteractCookie();
};

export default function () {
  const address = document.querySelector(ADDRESS_SEL);

  if (!address) return;

  document.addEventListener('selectionchange', handleAddressSelect);
  address.addEventListener('click', onAddressClick);
}
