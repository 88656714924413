// ================================================================================
// #ARTICLE PROGRESS COMPONENT
// ================================================================================

const PROGRESS_SEL = '.progress';
const PROGRESS_FILL_SEL = '.progress-circle';
const POST_SEL = '.post';
const NAVBAR_SEL = '.navbar';

function ProgressBar(element) {
  const circle = element.querySelector(PROGRESS_FILL_SEL);
  const article = document.querySelector(POST_SEL);
  const header = document.querySelector(NAVBAR_SEL);

  const radius = circle.r.baseVal.value;
  const circumference = 2 * Math.PI * radius;

  circle.style.strokeDasharray = `${circumference} ${circumference}`;
  circle.style.strokeDashoffset = circumference;

  window.addEventListener('scroll', function() {
    let windowScroll = document.documentElement.scrollTop - article.offsetTop + header.clientHeight;
    let windowHeight =
      article.scrollHeight - document.documentElement.clientHeight + header.clientHeight;

    if (windowScroll < 0) {
      windowScroll = 0;
    }
    if (windowScroll > windowHeight) {
      windowScroll = windowHeight;
    }

    let percent = Math.ceil((windowScroll / windowHeight) * 100);
    const offset = circumference - (percent / 100) * circumference;
    circle.style.strokeDashoffset = offset;
  });
}

function init() {
  const progress = document.querySelector(PROGRESS_SEL);

  if (!progress) return;

  ProgressBar(progress);
}

export default init;
