const lockBodyEvent = new CustomEvent('lock-body', { detail: { lock: true } });
const unlockBodyEvent = new CustomEvent('lock-body', {
  detail: { lock: false },
});

function dialog() {
  return {
    isDialogOpen: false,

    openDialog() {
      this.isDialogOpen = true;
      window.dispatchEvent(lockBodyEvent);

      if (this.$refs.video) {
        this.$refs.video.play();
      }
    },

    closeDialog() {
      this.isDialogOpen = false;
      window.dispatchEvent(unlockBodyEvent);

      if (this.$refs.video) {
        this.$refs.video.pause();
        this.$refs.video.currentTime = 0;
      }
    },
  };
}

export default dialog;
