import axios from 'axios';
import qs from 'querystring';

// const captchaSiteKey = '6LdCI9MUAAAAAE9OXtRKLAA8FwJWNmPU0gtvAefu';
const captchaSiteKey = '6LfdcdMUAAAAAGjDEgPZf-lGYgWQLMeiXm3xDGJE';

export function verifyCaptcha(token) {
  return axios({
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: `/captcha-verify.php`,
    data: qs.stringify({ token: token }),
  });
}

export function getCaptchaToken(field, action) {
  grecaptcha.ready(() => {
    grecaptcha
      .execute(captchaSiteKey, { action: action || 'homepage' })
      .then((token) => (field.value = token));
  });
}
