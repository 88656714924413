import 'script-loader!svgxuse';
import 'script-loader!dragscroll';
import 'script-loader!./vendor/modernizr-custom';
import 'script-loader!./vendor/perlin';
import smoothscroll from 'smoothscroll-polyfill';

import 'alpinejs';
import 'regenerator-runtime/runtime';

import textRotator from './components/TextRotator';
import Jumper from './components/Jumper';
import Stripe from './components/Stripe';
import HeroBlob from './components/HeroBlob';
import Reviews from './components/Reviews';
import Carousel from './components/Carousel';
import ContactsMap from './components/ContactsMap';
import Search from './components/Search';
import ArticleProgress from './components/ArticleProgress';
import ReferrerCookie from './components/ReferrerCookie';
import GeoRef from './components/GeoRef';
import AddressAction from './components/AddressAction';

import file from './components/file';
import contactsForm from './components/ContactsForm';
import selectMultiple from './components/selectMultiple';
import dialog from './components/dialog';
import vacancies from './components/vacancies';
import stages from './components/Stages';
import callbackForm from './components/callbackForm';
import whitepapper from './components/Whitepaper';
import portfolio from './components/Portfolio';
import cookieMessage from './components/CookiesMessage';
import nav from './components/Nav';
import Faq from './components/faq';
import ReasonDialog from './components/reasonDialog';
import designSection from './components/designSection';
import DetectUserIp from './components/DetectUserIp';

import '../styles/main.css';

smoothscroll.polyfill();

window.file = file;
window.contactsForm = contactsForm;
window.selectMultiple = selectMultiple;
window.dialog = dialog;
window.vacancies = vacancies;
window.stages = stages;
window.callbackForm = callbackForm;
window.whitepapper = whitepapper;
window.portfolio = portfolio;
window.cookieMessage = cookieMessage;
window.nav = nav;
window.designSection = designSection;

document.addEventListener('DOMContentLoaded', () => {
  ReferrerCookie();
  GeoRef();
  textRotator();
  Jumper();
  Stripe();
  HeroBlob();
  Reviews();
  Carousel();
  Search();
  ContactsMap();
  ArticleProgress();
  Faq();
  ReasonDialog();
  DetectUserIp();
  AddressAction();
});
