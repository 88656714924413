import { setCookie, getCookie } from '../helpers/cookiesUtils';
import { getTrafficCategory } from '../helpers/trafficSource';
import { getDateInMoscowTimezone } from '../helpers/dateUtils';

function init() {
  const referrer = getTrafficCategory(document);
  const referrerCookie = getCookie('user_referrer');
  const visitCookie = getCookie('first_visit');

  if (!visitCookie) {
    const visitDate = getDateInMoscowTimezone();
    setCookie('first_visit', visitDate);
  }

  if (!referrer || referrerCookie) return;

  setCookie('user_referrer', referrer, { 'max-age': 2592000 });
}

export default init;
