export const formMessages = {
  presence: {
    ru: '^Поле не может быть пустым',
    en: '^The field cannot be empty',
    fr: '^Le champ ne peut pas être vide',
  },
  phone: {
    ru: '^Введите корректный номер телефона в формате +7 915 100 20 30',
    en: '^Please enter the valid phone number in international format (+1) 000 000 00 00',
    fr: '^Saisissez le téléphone correct au format +33 0 00 00 00 00',
  },
  phoneOrEmail: {
    ru: '^Введите корректный адрес почты или телефон в формате +7 915 100 20 30',
    en: '^Please enter the valid e-mail or phone number in international format (+1) 000 000 00 00',
    fr: "^Saisissez l'adresse e-mail ou le téléphone correct au format +33 0 00 00 00 00",
  },
  fallback: {
    ru: '^Неправильное значение',
    en: '^Incorrect value',
    fr: '^Mauvaise valeur',
  },
  successMessage: {
    ru: 'Спасибо, мы скоро с вами свяжемся!',
    en: 'Thank you, we will contact you soon!',
    fr: 'Merci, nous vous contacterons bientôt!',
  },
};

export const whitepapperLabels = {
  expandLabel: {
    ru: 'Читать',
    en: 'Read',
    fr: 'Lire',
  },
  collapseLabel: {
    ru: 'Свернуть',
    en: 'Collapse',
    fr: 'Réduire',
  },
};
